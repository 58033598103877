import { gmConfig } from 'helpers/config';
import { gmRequest } from './';

export const getAuthenticationToken = (username, password) => {
  const { clientId, clientSecret } = gmConfig.bashFrontendOAuth;

  return gmRequest(
    '/api/v1/token',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: new URLSearchParams({
        grant_type: 'password',
        username,
        password,
        client_id: clientId,
        client_secret: clientSecret,
      }),
    },
    false
  );
};

export const sendResetPasswordByEmail = (email) =>
  gmRequest(`/api/v1/reset-password?email=${encodeURIComponent(email)}`);

export default {
  getAuthenticationToken,
  sendResetPasswordByEmail,
};
