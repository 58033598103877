import React from 'react';
import PropTypes from 'prop-types';
import BaseContainer from 'components/grid/BaseContainer';
import Grid from '@mui/material/Grid';

const EditorialContainer = ({
  backgroundColor,
  backgroundImage,
  children,
  className,
  disableGutters,
  justifyContent,
  ...rest
}) => (
  <BaseContainer
    maxWidth="xl"
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    className={className}
    disableGutters={disableGutters}
    {...rest}
  >
    <Grid container justifyContent={justifyContent} spacing={0}>
      <Grid item xl={8} lg={10} md={12}>
        {children}
      </Grid>
    </Grid>
  </BaseContainer>
);

EditorialContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  disableGutters: PropTypes.bool,
  justifyContent: PropTypes.string,
};

EditorialContainer.defaultProps = {
  justifyContent: 'flex-start',
  className: '',
  backgroundColor: null,
  backgroundImage: null,
  disableGutters: false,
};

export default EditorialContainer;
