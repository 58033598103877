import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { Button, Link } from '@tkww/the-bash-frontend';
import TextField from 'components/TextField';

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  fontFamily: theme.fonts.regular,
}));

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#FFF',
  },
}));

const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  textDecoration: 'uppercase',
  height: 58,
}));

const LoginForm = ({
  data,
  errors,
  isProcessing,
  handleChange,
  handleLogin,
  handleResetPasswordTracking,
}) => {
  const handleInputChange = (e) => {
    let dataValue;
    const { checked, value, id, type } = e.target;

    if (type === 'checkbox') {
      dataValue = checked;
    } else {
      dataValue = value;
    }

    handleChange({
      ...data,
      [id]: dataValue,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledTextField
        id="username"
        type="text"
        label="Username"
        value={data.username}
        onChange={handleInputChange}
        error={errors.username}
      />
      <StyledTextField
        id="password"
        type="password"
        label="Password"
        value={data.password}
        onChange={handleInputChange}
        error={errors.password}
      />
      <StyledLoginButton fullWidth loading={isProcessing} buttonType="submit">
        Log In
      </StyledLoginButton>
      {errors.general && (
        <Typography
          variant="small"
          color="error"
          sx={{ display: 'block', textTransform: 'uppercase' }}
        >
          {errors.general}
        </Typography>
      )}
      <FormControlLabel
        sx={{
          mb: 4,
        }}
        control={
          <Checkbox
            id="keepUserLoggedIn"
            color="primary"
            onChange={handleInputChange}
            checked={data.keepUserLoggedIn}
          />
        }
        label="Keep me logged in"
      />
      <StyledLink path="/reset-password" onClick={handleResetPasswordTracking}>
        Forgot username or password?
      </StyledLink>
    </form>
  );
};

LoginForm.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    keepUserLoggedIn: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    general: PropTypes.string,
  }).isRequired,
  isProcessing: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleResetPasswordTracking: PropTypes.func.isRequired,
};

export default LoginForm;
