import React from 'react';
import PropTypes from 'prop-types';

import MuiTextField from '@mui/material/TextField';
import MuiInputAdornment from '@mui/material/InputAdornment';

const TextField = ({ renderStartIcon, renderEndIcon, error, testId, ...props }) => {
  const dynamicProps = props;
  if (error) {
    dynamicProps.error = true;
    dynamicProps.helperText = <b>{error}</b>;
  }

  const { InputProps = {} } = dynamicProps;
  if (renderStartIcon) {
    InputProps.startAdornment = (
      <MuiInputAdornment style={{ marginRight: 2 }} position="start">
        {renderStartIcon()}
      </MuiInputAdornment>
    );
  }
  if (renderEndIcon) {
    InputProps.endAdornment = (
      <MuiInputAdornment position="end">{renderEndIcon()}</MuiInputAdornment>
    );
  }

  const { inputProps = {} } = dynamicProps;
  if (testId) {
    inputProps['data-testid'] = testId;
  }

  dynamicProps.InputProps = InputProps;
  dynamicProps.inputProps = inputProps;

  return <MuiTextField {...dynamicProps} />;
};

TextField.propTypes = {
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  renderEndIcon: PropTypes.func,
  renderStartIcon: PropTypes.func,
  testId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
};

TextField.defaultProps = {
  error: null,
  fullWidth: true,
  margin: 'normal',
  placeholder: '',
  renderEndIcon: null,
  renderStartIcon: null,
  testId: null,
  type: 'text',
  value: '',
  variant: 'outlined',
};

export default TextField;
