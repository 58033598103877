import { useRouter } from 'next/router';

const useQueryParameters = () => {
  const router = useRouter();
  const lowerCasedQueryParameters = Object.keys(router.query).reduce((accumulator, key) => {
    // eslint-disable-next-line no-param-reassign
    accumulator[key.toLowerCase()] = router.query[key];
    return accumulator;
  }, {});

  const getQueryParameterValue = (parameterName) =>
    lowerCasedQueryParameters[parameterName.toLowerCase()];

  return {
    getQueryParameterValue,
  };
};

export default useQueryParameters;
