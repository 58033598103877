import React from 'react';
import LoginPageView from 'views/LoginPage';
import Head from 'next/head';
import buildPageAnalytics from 'helpers/buildPageAnalytics';
import { LOGIN_PAGE_NAME } from 'constants/pageNames';
import configureStore from 'state/configureStore';
import getServerSidePropsWrapper from 'helpers/getServerSidePropsWrapper';
import { gmConfig } from 'helpers/config';

const LoginPage = () => (
  <>
    <Head>
      <title>Login | The Bash</title>
      <link rel="canonical" href={`https://${gmConfig.domains.www}/login`} key="canonical" />
    </Head>
    <LoginPageView />
  </>
);

export const getServerSideProps = configureStore.getServerSideProps(
  (store) => async (ctx) =>
    await getServerSidePropsWrapper({ ...ctx, store }, async (ctx) => {
      const pageAnalytics = buildPageAnalytics(LOGIN_PAGE_NAME);
      return {
        ...pageAnalytics,
      };
    })
);

export default LoginPage;
