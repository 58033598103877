import React from 'react';
import { Typography } from '@tkww/the-bash-frontend';
import { Box, CircularProgress } from '@mui/material';

const LoggingIn = () => (
  <>
    <Box sx={{ mt: 15, mb: 2 }}>
      <CircularProgress />
    </Box>
    <Typography variant="body">Please Hold - Logging you in</Typography>
  </>
);

export default LoggingIn;
