import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { GREY_50 } from 'styles/colors';
import { SectionContainer } from 'components/grid';
import { Typography } from '@tkww/the-bash-frontend';

const StyledLeftSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -46,
  height: 'calc(100% + 46px)',
  width: 63,
  backgroundColor: '#E3F7F2',
  clipPath:
    "path('M-307.463 79.9713C-407.064 320.465 -315.795 499.421 -197.761 632.417C-136.57 701.365 -15.4369 641.677 21.9186 536.975C83.7207 363.753 73.9317 205.295 -6.60937 79.9712C-87.1504 -45.3521 -234.38 -96.4923 -307.463 79.9713Z')",
  [theme.breakpoints.up('md')]: {
    height: '100%',
    top: 0,
    clipPath:
      "path('M-196.791 74.6278C-316.699 375.59 -206.821 599.543 -64.7215 765.979C8.94498 852.262 154.775 777.567 199.747 646.539C274.149 429.762 262.364 231.462 165.402 74.6279C68.4403 -82.2064 -108.807 -146.205 -196.791 74.6278Z')",
    width: 249,
  },
}));

const StyledRightSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -85,
  right: 0,
  height: '100%',
  width: 55,
  backgroundColor: theme.palette.citrine[100],
  clipPath:
    "path('M440.843 381.355C547.405 190.948 449.757 49.2621 323.474 -56.0361C234.134 -130.531 34.6584 -10.2355 8.35253 110.985C-25.7598 268.178 50.1094 438.053 136.279 537.276C222.448 636.499 362.653 521.067 440.843 381.355Z')",
  [theme.breakpoints.up('md')]: {
    top: 0,
    width: 206,
    clipPath:
      "path('M440.523 577.995C547.008 323.627 449.431 134.347 323.24 -6.32223C233.964 -105.841 34.6337 54.8636 8.34688 216.804C-25.7407 426.8 50.0735 653.739 136.18 786.292C222.287 918.845 362.39 764.638 440.523 577.995Z')",
  },
}));

const AuthenticationSection = ({ title, subtitle, children }) => (
  <SectionContainer
    backgroundColor={GREY_50}
    leftContent={<StyledLeftSection />}
    rightContent={<StyledRightSection />}
    sx={{
      position: 'relative',
    }}
    containerProps={{
      sx: {
        position: 'relative',
        zIndex: 1,
        textAlign: 'center',
        width: { md: 416 },
        minHeight: { xs: '85vh', md: 780 },
        maxHeight: { md: 876 },
        pl: { md: 0 },
        pr: { md: 0 },
        pb: { xs: 6, md: 12 },
        pt: { xs: 5.25, md: 8.25 },
      },
    }}
  >
    <Typography variant="h1" align="center" sx={{ marginTop: 0 }}>
      {title}
    </Typography>
    <Typography variant="body1" align="center" sx={{ mb: 1 }}>
      {subtitle}
    </Typography>
    {children}
  </SectionContainer>
);

AuthenticationSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default AuthenticationSection;
